<template>
    <transition name="modal" v-if="isActive">
        <perfect-scrollbar class="modal-mask">
            <div class="modal-form modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4>
                            <b-icon icon="pencil-square"></b-icon>
                            <span>กำหนดโปรโมชั่น</span>
                        </h4>

                        <button class="btn-close" @click="hide(false)"><b-icon icon="x"></b-icon></button>
                    </div>
                    <div class="modal-body">
                        <div class="form">
                            <div class="row">
                                <div class="col col-12">
                                    <div class="input-field">
                                        <label>ประเภทโปรโมชั่น</label>
                                        <dropdown
                                            v-model="form.promotionType"
                                            placeholder="เลือกประเภทโปรโมชั่น"
                                            :disabled="editData ? true : false"
                                            :classes="{
                                                input: [
                                                    'square',
                                                    'input-option',
                                                    error.promotionType ? 'invalid' : ''
                                                ]
                                            }"
                                            :options="optionType">
                                        </dropdown>
                                    </div>
                                </div>
                            </div>
                            <template v-if="form.promotionType">
                                <div class="row">
                                    <div class="col col-12 col-sm-6"
                                        v-if="form.promotionType === 'discountAmount'
                                        || form.promotionType === 'discountPercent'
                                        || form.promotionType === 'discountTurnPart'">
                                        <div class="input-field">
                                            <label>กำหนดส่วนลด ต่อเส้น</label>
                                            <div class="input-unit">
                                                <input type="number"
                                                    @keypress="$AppUtility.inputOnlyNumber($event)"
                                                    v-model="form.amount"
                                                    :placeholder="displayDiscountPlaceholder()"
                                                    class="square"
                                                    :class="{ 'invalid': error.amount }" />
                                                <span>{{ displayDiscountUnit() }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col col-12 col-sm-6"
                                        v-if="form.promotionType === 'discountOrderAmt'
                                            || form.promotionType === 'discountOrderPercent'">
                                        <div class="input-field">
                                            <label>กำหนดส่วนลดต่อรายการสั่งซื้อ</label>
                                            <span class="sub">* ใช้ได้กับรายการสั่งซื้อที่มีจำนวน 4 เส้นเท่านั้น</span>
                                            <div class="input-unit">
                                                <input type="number"
                                                    @keypress="$AppUtility.inputOnlyNumber($event)"
                                                    v-model="form.amount"
                                                    :placeholder="displayDiscountPlaceholder()"
                                                    class="square"
                                                    :class="{ 'invalid': error.amount }" />
                                                <span>{{ displayDiscountUnit() }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row" v-if="form.promotionType === 'delivery'">
                                    <div class="col col-12 col-sm-6">
                                        <div class="input-field">
                                            <div class="delivery-condition">
                                                <div class="checkbox">
                                                    <checkbox
                                                        v-model="form.delivery.requireMinQty"
                                                        @change="changeDeliveryRequireQty($event)">
                                                        กำหนดจำนวนเส้นขั้นต่ำ
                                                    </checkbox>
                                                </div>
                                                <div class="input">
                                                    <div class="blocker" v-if="!form.delivery.requireMinQty"></div>
                                                    <select
                                                        v-model="form.delivery.minQty"
                                                        class="square"
                                                        :class="{ 'invalid': error.deliveryMinQty }">
                                                        <option disabled :value="null">-- จำนวนขั้นต่ำ --</option>
                                                        <option value="1">1 เส้น</option>
                                                        <option value="2">2 เส้น</option>
                                                        <option value="3">3 เส้น</option>
                                                        <option value="4">4 เส้น</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col col-12">
                                        <div class="input-field">
                                            <label>พื้นที่ที่ร่วมรายการ</label>
                                            <div class="delivery-zone">
                                                <div class="checkbox">
                                                    <checkbox
                                                        v-model="form.delivery.zone1">
                                                        โซนที่ 1
                                                    </checkbox>
                                                </div>
                                                <div class="checkbox">
                                                    <checkbox
                                                        v-model="form.delivery.zone2">
                                                        โซนที่ 2
                                                    </checkbox>
                                                </div>
                                                <div class="checkbox">
                                                    <checkbox
                                                        v-model="form.delivery.zone3">
                                                        โซนที่ 3
                                                    </checkbox>
                                                </div>
                                                <div class="checkbox">
                                                    <checkbox
                                                        v-model="form.delivery.zone4">
                                                        โซนที่ 4
                                                    </checkbox>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col col-12 col-sm-6">
                                        <div class="input-field">
                                            <label>ตั้งแต่วันที่</label>
                                            <div class="input-date-icon">
                                                <datepicker
                                                    v-model="form.fromDate"
                                                    @input="changeDateStart()"
                                                    :disabled-dates="getDisableDate()"
                                                    :input-class="[ 'input-date', 'square', (error.fromDate ? 'invalid' : '') ]"
                                                    :language="calendarTH"
                                                    :full-month-name="true"
                                                    calendar-class="datepicker-box"
                                                    placeholder="วันที่เริ่มต้น"
                                                    format="dd/MM/yyyy">
                                                </datepicker>
                                                <b-icon icon="calendar"></b-icon>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col col-12 col-sm-6">
                                        <div class="input-field">
                                            <label>จนถึง</label>
                                            <div class="input-date-icon">
                                                <datepicker
                                                    v-model="form.toDate"
                                                    :disabled-dates="getDisableDate()"
                                                    :input-class="[ 'input-date', 'square', (error.toDate ? 'invalid' : '') ]"
                                                    :language="calendarTH"
                                                    :full-month-name="true"
                                                    calendar-class="datepicker-box"
                                                    placeholder="วันที่สิ้นสุด"
                                                    format="dd/MM/yyyy">
                                                </datepicker>
                                                <b-icon icon="calendar"></b-icon>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col col-12">
                                        <div class="input-field">
                                            <label>เงื่อนไขและคำบรรยาย</label>
                                            <input type="text"
                                                v-model="form.description"
                                                maxlength="500"
                                                placeholder="บรรยายเงื่อนไข"
                                                class="square"
                                                :class="{ 'invalid': error.description }" />
                                        </div>
                                    </div>
                                    <div class="col col-12" v-if="form.promotionType === 'buy1get1'">
                                        <div class="input-field">
                                            <div class="qty-condition">
                                                <b-form-group>
                                                    <b-form-radio 
                                                        v-model="form.qtyCondition"
                                                        name="qtyCondition" 
                                                        value="option1">
                                                        ต้องซื้ออย่างน้อย 2 ชิ้น
                                                    </b-form-radio>
                                                    <b-form-radio 
                                                        v-model="form.qtyCondition" 
                                                        name="qtyCondition" 
                                                        value="option2">
                                                        ต้องซื้ออย่างน้อย 4 ชิ้น
                                                    </b-form-radio>
                                                </b-form-group>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col col-12" v-if="form.promotionType === 'installment'">
                                        <div class="input-field">
                                            <label>จำนวนเดือนที่ผ่อนได้</label>
                                            <select
                                                v-model="form.installmentMaxPeriod"
                                                class="square"
                                                :class="{ 'invalid': error.installmentMaxPeriod }"
                                                @change="changeInstallmentMaxPeriod($event)">
                                                <option disabled :value="null">-- เลือกจำนวนเดือน --</option>
                                                <option v-for="(item, index) in optionInstallmentPeriod" :key="index" :value="item.id">{{ item.name }}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <!-- Hide Feature -->
                                    <!-- <div class="col col-12" v-if="form.promotionType === 'installment' && form.installmentMaxPeriod">
                                        <div class="input-field">
                                            <label>
                                                สำหรับเพิ่มราคาสินค้าผ่อน 0%
                                                <span class="sub">* ไม่จำเป็นต้องติ๊กด้านล่างถ้าหากต้องการขายตามราคาปกติที่ตั้งไว้</span>
                                            </label>
                                            <div class="installment-charge" v-for="(item, index) in installmentChargeList" :key="index">
                                                <div class="checkbox">
                                                    <checkbox
                                                        v-model="form.installmentCharge[item.property].isEnable"
                                                        @change="changeChargeEnable($event, item.property)"
                                                        class="checker-charge">
                                                        กรณีผ่อน {{ item.month }} เดือน
                                                    </checkbox>
                                                </div>
                                                <div class="option">
                                                    <div class="blocker" v-if="!form.installmentCharge[item.property].isEnable"></div>
                                                    <select class="square" 
                                                            v-model="form.installmentCharge[item.property].chargeType">
                                                        <option v-for="(option, optionIndex) in optionChargeType" :key="optionIndex" :value="option.key">{{ option.text }}</option>
                                                    </select>
                                                </div>
                                                <div class="input">
                                                    <div class="blocker" v-if="!form.installmentCharge[item.property].isEnable"></div>
                                                    <div class="input-unit">
                                                        <input type="tel"
                                                            v-model="form.installmentCharge[item.property].charge"
                                                            @keypress="$AppUtility.inputOnlyNumber($event)"
                                                            :placeholder="form.installmentCharge[item.property].chargeType === 'percent' ? '% ราคาที่เพิ่ม' : 'ระบุราคาที่เพิ่ม'"
                                                            class="square"
                                                            maxlength="6"
                                                            :class="{ 'invalid': error.charge[item.property] }" />
                                                        <span v-if="form.installmentCharge[item.property].chargeType === 'percent'">%</span>
                                                        <span v-if="form.installmentCharge[item.property].chargeType === 'amount'">บาท</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> -->
                                </div>
                            </template>
                        </div>

                        <div class="text-center mt-1" v-if="form.promotionType">
                            <button class="btn btn-main btn-submit square" @click="submitPromotion()">บันทึก</button>
                        </div>
                    </div>
                </div>
            </div>

            <modal-save-product-loader
                :isDisplay="isLoadSaveProduct"
                :savedProduct="savedProduct"
                :totalProduct="totalProduct">
            </modal-save-product-loader>
        </perfect-scrollbar>
    </transition>
</template>

<script>
import { th } from 'vuejs-datepicker/dist/locale';
import moment from 'moment';
import Helper from '@/services/helper';
import ModalSaveProductLoader from '@/components/seller/stocks/ModalSaveProductLoader';
import SellerService from '@/services/sellerService';

export default {
    components: { ModalSaveProductLoader },
    data() {
		return {
            calendarTH: th,
            isActive: false,
            form: null,
            error: null,
            optionInstallmentPeriod: [
                { id: '3', name: '3 เดือน' },
                { id: '4', name: '3, 4 เดือน' },
                { id: '6', name: '3, 4, 6 เดือน' },
                { id: '10', name: '3, 4, 6, 10 เดือน' },
            ],
            optionChargeType: [
                { key: 'percent', text: 'ระบุด้วย %' },
                { key: 'amount', text: 'ระบุด้วยราคา' }
            ],
            installmentChargeList: [],
            isLoadSaveProduct: false,
            savedProduct: 0,
            totalProduct: 0
		};
	},
	props: {
		isDisplay: Boolean,
        editData: Object,
        category: Number,
        optionType: Array
	},
    watch: {
        async isDisplay(value) {
            this.isActive = value;
            this.$root.$emit('rootSetContentScrollable', value);

            if (value) {
                this.form = this.initForm();
                this.error = this.initError();

                if (this.editData) {
                    this.savedProduct = 0;
                    this.totalProduct = this.editData.productIntegrated.length;

                    this.generateInstallmentChargeList(this.editData.installmentMaxPeriod);
                }
            }
        }
    },
    destroyed() {
        this.$root.$emit('rootSetContentScrollable', false);
    },
	methods: {
        hide(isReload) {
            this.$emit('close', { isReload: isReload });
        },
        initForm() {
            return {
                id: this.editData ? this.editData.id : '',
                promotionType: this.editData ? this.editData.alias : '',
                amount: this.editData ? this.editData.amount : '',
                fromDate: this.editData ? this.editData.fromDate : '',
                toDate: this.editData ? this.editData.toDate : '',
                description: this.editData ? this.editData.description : '',
                qtyCondition: this.editData ? this.editData.qtyCondition : '',
                delivery: {
                    requireMinQty: this.editData && this.editData.delivery ? this.editData.delivery.requireMinQty : false,
                    minQty: this.editData && this.editData.delivery ? this.editData.delivery.minQty : null,
                    zone1: this.editData && this.editData.delivery ? this.editData.delivery.zone1 : true,
                    zone2: this.editData && this.editData.delivery ? this.editData.delivery.zone2 : true,
                    zone3: this.editData && this.editData.delivery ? this.editData.delivery.zone3 : true,
                    zone4: this.editData && this.editData.delivery ? this.editData.delivery.zone4 : true,
                },
                installmentMaxPeriod: this.editData ? this.editData.installmentMaxPeriod : null,
                    installmentCharge: this.editData ? this.editData.installmentCharge : {
                        month3: { isEnable: false, chargeType: '', charge: '' },
                        month4: { isEnable: false, chargeType: '', charge: '' },
                        month6: { isEnable: false, chargeType: '', charge: '' },
                        month10: { isEnable: false, chargeType: '', charge: '' },
                    }
            };
        },
        initError() {
            return {
                promotionType: false,
                amount: false,
                fromDate: false,
                toDate: false,
                description: false,
                qtyCondition: false,
                deliveryMinQty: false,
                installmentMaxPeriod: false,
                charge: {
                    month3: false,
                    month4: false,
                    month6: false,
                    month10: false,
                }
            };
        },
        displayDiscountPlaceholder() {
            let placeholder = '';

            switch (this.form.promotionType) {
                case 'discountAmount':
                case 'discountTurnPart':
                    placeholder = 'ราคาที่จะลด'
                    break;

                case 'discountPercent':
                    placeholder = 'เปอร์เซ็นส่วนลด'
                    break;

                case 'discountOrderAmt':
                    placeholder = 'ราคาที่จะลดสำหรับทั้งรายการ'
                    break;

                case 'discountOrderPercent':
                    placeholder = 'เปอร์เซ็นส่วนลดสำหรับทั้งรายการ'
                    break;
            }

            return placeholder;
        },
        displayDiscountUnit() {
            return Helper.displayPromotionAmountUnit(this.form.promotionType);
        },
        changeDateStart() {
            this.form.toDate = '';
        },
        changeDeliveryRequireQty(value) {
            if (!value) {
                this.form.delivery.minQty = '';
            }
        },
        changeChargeEnable(value, property) {
            if (value) {
                this.form.installmentCharge[property].chargeType = 'percent';
            } else {
                this.form.installmentCharge[property].chargeType = '';
                this.form.installmentCharge[property].charge = '';
            }
        },
        changeInstallmentMaxPeriod(e) {
            this.generateInstallmentChargeList(e.target.value);
        },
        generateInstallmentChargeList(value) {
            this.installmentChargeList = [];

            if (value) {
                const list = [
                    { month: 3, property: 'month3' },
                    { month: 4, property: 'month4' },
                    { month: 6, property: 'month6' },
                    { month: 10, property: 'month10' }
                ];

                list.forEach((item) => {
                    if (item.month <= value) {
                        this.installmentChargeList.push(item);
                    }
                });
            }
        },
        getDisableDate() {
            return { to: moment(moment(), 'YYYY-MM-DD').subtract(1, 'day').toDate() };
        },
        async submitPromotion() {
            if (this.validate()) {
                // Prevent date get converted
                this.form.fromDate = moment(this.form.fromDate).format('YYYY-MM-DD').toString();
                this.form.toDate = moment(this.form.toDate).format('YYYY-MM-DD').toString();
                
                this.isLoadSaveProduct = true;
                const result = await SellerService.savePromotion(this.form);

                if (result.data?.success) {
                    if (this.editData) {
                        this.savedProduct = 0;

                        for (let sellerProductId of this.editData.productIntegrated) {
                            this.savedProduct++;
                            await SellerService.updatePromotionProduct(sellerProductId, this.category);
                        }
                    }

					this.hide(true);
				} else {
					this.$swal(Helper.warningAlert('ไม่สามารถบันทึกโปรโมชั่นได้', result.data?.message));
				}

                this.isLoadSaveProduct = false;
            }
        },
        validate() {
            this.error = this.initError();
            let isValid = true;
            let isBlank = false;

            // Check Blank
            if (!this.form.promotionType) {
                this.error.promotionType = true;
                isValid = false;
                isBlank = true;
            }
            if (this.form.promotionType === 'discountAmount'
                || this.form.promotionType === 'discountPercent'
                || this.form.promotionType === 'discountTurnPart') {
                if (!this.form.amount) {
                    this.error.amount = true;
                    isValid = false;
                    isBlank = true;
                }
            }
            if (!this.form.fromDate) {
                this.error.fromDate = true;
                isValid = false;
                isBlank = true;
            }
            if (!this.form.toDate) {
                this.error.toDate = true;
                isValid = false;
                isBlank = true;
            }
            if (!this.form.description) {
                this.error.description = true;
                isValid = false;
                isBlank = true;
            }
            if (this.form.promotionType === 'buy1get1') {
                if (!this.form.qtyCondition) {
                    this.error.qtyCondition = true;
                    isValid = false;
                    isBlank = true;
                }
            }
            if (this.form.promotionType === 'delivery') {
                if (this.form.delivery.requireMinQty && !this.form.delivery.minQty) {
                    this.error.deliveryMinQty = true;
                    isValid = false;
                    isBlank = true;
                }
            }
            if (this.form.promotionType === 'installment') {
                if (!this.form.installmentMaxPeriod) {
                    this.error.installmentMaxPeriod = true;
                    isValid = false;
                    isBlank = true;
                }

                this.installmentChargeList.forEach((item) => {
                    if (this.form.installmentCharge[item.property].isEnable) {
                        if (!this.form.installmentCharge[item.property].charge) {
                            this.error.charge[item.property] = true;
                            isValid = false;
                            isBlank = true;
                        }
                    }
                });
            }

            // Popup
            if (isBlank) {
                this.$swal(Helper.warningAlert('ไม่สามารถบันทึกโปรโมชั่นได้', 'โปรดกรอกข้อมูลให้ครบถ้วน'));
            }

            return isValid;
        }
	}
}
</script>

<style lang="scss" scoped>
@import '@/theme/Variable.scss';
@import '@/components/seller/style/ModalStock.scss';

.delivery-condition {
    position: relative;
    font-size: 13px;
    margin-bottom: 5px;

    .blocker {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: #fff;
        opacity: 0.6;
        z-index: 1;
        cursor: not-allowed;
    }

    .input {
        position: relative;
        margin-top: 5px;
    }
}

.delivery-zone {
    display: flex;
    flex-wrap: wrap;

    .checkbox {
        margin-right: 10px;
    }
}

.installment-charge {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    font-size: 13px;
    margin-bottom: 5px;

    .blocker {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: #fff;
        opacity: 0.6;
        z-index: 1;
        cursor: not-allowed;
    }

    .checkbox {
        width: 30%;
        align-items: center;

        @media only screen and (max-width: $screenExtraSmall) {
            width: 100%;
        }
    }

    .option {
        position: relative;
        width: 35%;
        padding-left: 5px;

        @media only screen and (max-width: $screenExtraSmall) {
            width: 50%;
        }
    }

    .input {
        position: relative;
        width: 35%;
        padding-left: 5px;

        @media only screen and (max-width: $screenExtraSmall) {
            width: 50%;
        }
    }
}
</style>