<template>
	<section>
		<h2>จัดการโปรโมชั่น</h2>

		<div class="folder">
			<div class="title">
				<div class="tab">
					<button class="btn-category" :class="{ 'active': selectedCategory === 1 }" @click="changeCategory(1)">โปรโมชั่นส่วนลด</button>
					<button class="btn-category" :class="{ 'active': selectedCategory === 2 }" @click="changeCategory(2)">ผ่อน0%/ของแถม/ส่งฟรี</button>
				</div>
				<div class="operation">
					<button @click="exportExcel()">
						<b-icon icon="download"></b-icon>
					</button>
				</div>	
			</div>

			<div class="text-center mb-3">
				<button class="btn btn-main btn-add-new" @click="openPromotionForm(null)">เพิ่มโปรโมชั่น</button>
			</div>
			
			<seller-product-manage-filter class="mb-3" :optionFilter="optionFilter" @changeFilter="changeFilter($event)"></seller-product-manage-filter>

			<template v-if="isResultReady">
				<div class="vue-dragscoll" v-dragscroll.x @dragscrollstart="dragStart()" @dragscrollend="dragEnd()" @click.capture="dragClick($event)">
					<table>
						<thead>
							<tr>
								<th class="action-colummn"></th>
								<th>ช่วงเวลา</th>
								<th>ประเภท</th>
								<th>ส่วนลด</th>
								<th>เงื่อนไข</th>
								<th class="text-center">รายการสินค้า</th>
								<th class="action-colummn"></th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(item, index) in resultList" :key="index">
								<td class="text-center">
									<button @click="openPromotionForm(item)" class="btn btn-sm square btn-warning">แก้ไข</button>
								</td>
								<td>
									<span class="text-danger" v-if="item.isExpired">หมดอายุแล้ว</span>
									<span v-if="!item.isExpired"><i>จาก</i> {{ item.fromDate }}</span>
									<span v-if="!item.isExpired"><i>ถึง</i> {{ item.toDate }}</span>
								</td>
								<td>{{ item.name }}</td>
								<td>{{ item.amount || '-' }} {{ displayUnit(item.id) }}</td>
								<td >
									<span v-if="item.qtyCondition === 'option1'">ต้องซื้ออย่างน้อย 2 ชิ้น</span>
									<span v-if="item.qtyCondition === 'option2'">ต้องซื้ออย่างน้อย 4 ชิ้น</span>
									<span>{{ item.description }}</span>
								</td>
								<td class="text-center">
									<button class="btn btn-sm square btn-outline-danger" @click="openIntegratedProduct(item)">สินค้าร่วมรายการ ({{ item.productIntegrated.length }})</button>
								</td>
								<td class="text-center">
									<button @click="deletePromotion(item)" class="btn btn-sm square btn-danger">ลบ</button>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</template>

			<div class="no-data" v-if="isResultReady && resultList.length === 0">
				<b-icon icon="box-seam"></b-icon>
				<span>ไม่มีรายการโปรโมชั่น</span>
			</div>
		</div>

		<modal-promotion
            @close="closePromotionForm($event)"
            :editData="selectedPromotion"
			:optionType="optionPromotion"
			:category="selectedCategory"
            :isDisplay="isOpenPromotionForm">
        </modal-promotion>

		<modal-integrated-product
            @close="closeIntegratedProduct()"
			@reloadContent="reloadContent($event)"
            :title="promotionForIntegration.title"
			:description="promotionForIntegration.description"
			:products="promotionForIntegration.products"
			:keyForIntegration="promotionForIntegration.id"
			typeForIntegration="promotion"
            :isDisplay="isOpenIntegratedProduct">
        </modal-integrated-product>
	</section>
</template>

<script>
import moment from 'moment';
import MixinDragScroll from '@/mixins/dragScroll';
import SellerProductManageFilter from '@/components/seller/stocks/ProductManageFilter';
import ModalPromotion from '@/components/seller/stocks/ModalPromotion';
import ModalIntegratedProduct from '@/components/seller/stocks/ModalIntegratedProduct';
import MasterService from '@/services/masterService';
import SellerService from '@/services/sellerService';
import Helper from '@/services/helper';
import RouterPath from '@/router/path';

export default {
    mixins: [ MixinDragScroll ],
	components: {
		SellerProductManageFilter,
		ModalPromotion,
		ModalIntegratedProduct
	},
	data() {
		return {
			isOpenPromotionForm: false,
			isOpenIntegratedProduct: false,
			selectedCategory: 1, // 1 - โปรโมชั่นแบบ 1 รายการ, 2 - โปรโมชั่นแบบอื่นๆ
			selectedType: 'all',
			selectedPromotion: null,
			promotionForIntegration: {
				id: 0,
				title: '',
				description: '',
				products: null,
				data: null
			},
			optionFilter: [],
			optionPromotion: [],
			isResultReady: false,
			resultList: []
		}
	},
	mounted() {
		this.initPromotionList();
		this.getStockPromotion();
	},
	methods: {
		openPromotionForm(promotion) {
			this.selectedPromotion = promotion;
            this.isOpenPromotionForm = true;
        },
        closePromotionForm(data) {
            this.isOpenPromotionForm = false;

            if (data.isReload) {
                this.getStockPromotion();
            }
        },
		openIntegratedProduct(promotion) {
			this.setupPromotionIntegration(promotion);

			this.isOpenIntegratedProduct = true;
		},
		closeIntegratedProduct() {
			this.isOpenIntegratedProduct = false;
		},
		setupPromotionIntegration(promotion) {
			this.promotionForIntegration = {
				id: promotion.id,
				title: this.getTitle(promotion),
				description: promotion.description,
				products: promotion.productIntegrated,
				data: promotion
			};
		},
		getTitle(promotion) {
			let title = promotion.name;

			if (promotion.alias === 'discountAmount'
				|| promotion.alias === 'discountTurnPart'
				|| promotion.alias === 'discountPercent') {
				title += ' (ส่วนลด ' + promotion.amount + Helper.displayPromotionAmountUnit(promotion.alias) + ')';
			}

			title += ' - ตั้งแต่ ' + moment(promotion.fromDate, 'YYYY-MM-DD').format('DD/MM/YYYY') + ' ถึง ' + moment(promotion.toDate, 'YYYY-MM-DD').format('DD/MM/YYYY');

			return title;
		},
		async initPromotionList() {
			const result = await MasterService.masterGetPromotionList(this.selectedCategory);
			const options = [];

			options.unshift({
				id: 'all',
				name: 'แสดงทั้งหมด'
			});

            result.data.forEach((item) => {
                options.push({
                    id: item.id,
                    name: item.name
                });
            });

			this.optionFilter = options;
			this.optionPromotion = options.filter(x => x.id !== 'all');
		},
		displayUnit(type) {
            return Helper.displayPromotionAmountUnit(type);
		},
		async getStockPromotion() {
			let loader = this.$modalLoader.render();
	
            const result = await SellerService.getStockPromotion(this.selectedCategory, this.selectedType === 'all' ? '' : this.selectedType);

			if (result.status == 401) {
				this.$router.push(RouterPath.SELLER_LOGIN);
			}

			this.isResultReady = true;
			this.resultList = result.data.resultList;

            loader.hide();
		},
		async reloadContent(callback) {
			await this.getStockPromotion();
			const currentPromotion = this.resultList.find(x => x.id === this.promotionForIntegration.id);
			this.setupPromotionIntegration(currentPromotion);
			callback();
		},
		changeCategory(category) {
			this.selectedCategory = category;
			this.selectedType = 'all';

			this.initPromotionList();
			this.getStockPromotion();
		},
		changeFilter(type) {
			if (type) {
				this.selectedType = type;

				this.getStockPromotion();
			}
		},
		deletePromotion(item) {
			this.$swal(
                Helper.confirmAlert('ยืนยันการลบโปรโมชั่น', 'โปรดยืนยันว่าท่านต้องการลบโปรโมชั่น "' + item.name + '" ออกจากรายการ')
            ).then(async (confirm) => {
                if (confirm.isConfirmed) {
                    let loader = this.$modalLoader.render();
					
					await SellerService.deletePromotion(item.id);
					this.getStockPromotion();

					loader.hide();
                }
            });
		},
		async exportExcel() {
            let loader = this.$modalLoader.render();
			const result = await SellerService.exportProductIntegration('promotion');
			loader.hide();

			if (result.data?.success) {
                Helper.fileDownloadByApi(result.data?.file);
			} else {
				this.$swal(Helper.warningAlert('ไม่สามารถดาวน์โหลดไฟล์ Excel ได้', result.data?.message));
			}
        }
	}
}
</script>

<style lang="scss" scoped>
@import '@/components/seller/style/ProductManage.scss';
</style>